/**
 * Contenitore dei dati dell'applicazione
 */

module LibraryApplicationData {
    let app = angular.module("app");

    app.factory('LibraryApplicationData', ["$translate", ($translate: angular.translate.ITranslateService) => {
        let LibraryApplicationData = {

        format: () => {
            let ftm = $translate.instant('generic.DATE_FORMAT');
            if (ftm && ftm.length > 0) {
                return ftm;
            }
            return 'dd/MM/yyyy';
        },

		/**
		 * Costanti relative all'applicazione
		 */
		constants: {
            // Numero di persone da mostrare per ogni "paginata"
            NETWORK_SEARCH_SIZE: 5,
            // Numero mimino di feedback da ricevere per considerare l'area chiusa
            MIN_NUMBER_OF_FEEDBACK: 5,

            // Autorizzazione per modificare e approvare i piani di altri utenti
            DEVPATH_MANAGE_PLAN: "DEVPATH_MANAGE_PLAN", 

            LOGGED_USER_NOT_FOUND: "LOGGED_USER_NOT_FOUND",
            USER_NOT_AUTHORIZED: "USER_NOT_AUTHORIZED",
            USER_WITHOUT_AUTHS: "USER_WITHOUT_AUTHS",

            DEVPATH_ACK_APPLICATION_TOUR: "DEVPATH_ACK_APPLICATION_TOUR",

            LIBRARY_ACK_APPLICATION_TOUR: "LIBRARY_ACK_APPLICATION_TOUR",
                
            // Permette di gestire un Progetto come amministratore
            LIBRARY_ADMIN_MANAGE_PROJECT: "LIBRARY_ADMIN_MANAGE_PROJECT",
            // Permette di gestire un Learning Plan come amministratore
            LIBRARY_ADMIN_MANAGE_LEARNING_PLAN: "LIBRARY_ADMIN_MANAGE_LEARNING_PLAN",
            // Permette di gestire i propri Learning Plan
            LIBRARY_MANAGE_LEARNING_PLAN: "LIBRARY_MANAGE_LEARNING_PLAN",
            // Permette di gestire i gruppi come admin
            LIBRARY_ADMIN_MANAGE_GROUPS: "LIBRARY_ADMIN_MANAGE_GROUPS",
            // Permette di gestire gli item come admin
            LIBRARY_ADMIN_MANAGE_ITEM: "LIBRARY_ADMIN_MANAGE_ITEM",

            PLAN_STATUS_OPEN: 'OPEN',
            PLAN_STATUS_APPROVED: 'APPROVED',
            PLAN_STATUS_PROGRESS: 'PROGRESS',
            PLAN_STATUS_WAITING_APPROVAL: 'WAITING_APPROVAL',
            PLAN_STATUS_WAITING_VALIDATION: 'WAITING_VALIDATION',
            PLAN_STATUS_REFUSED: 'REFUSED',
            PLAN_STATUS_CLOSED: 'CLOSED', // Piano archiviato
            PLAN_STATUS_READY_TO_BE_VALIDATED: 'READY_TO_BE_VALIDATED', // Piano pronto per essere mandato in validazione
            PLAN_STATUS_VALIDATED: 'VALIDATED', // Piano validato

            STATUS_SELECTED: "SELECTED",                    //area selezionata da iniziare
            STATUS_PROGRESS: "PROGRESS",                    //area in corso di svolgimento
            STATUS_WAITING_FEEDBACK: "WAITING_FEEDBACK",    //area in attesa di feedback
            STATUS_CLOSED: "CLOSED",                        //area conclusa

            CUSTOM_ACTIONS: 'CUSTOM_ACTIONS',

            APPROACH: "APPROACH",

            TEST_MINIMUM_SCORE_PERC: "TEST_MINIMUM_SCORE_PERC",

            DEVPATH: "DEVPATH",
            TRAININGPLATFORM: "TRAININGPLATFORM",
            LIBRARY: "LIBRARY",
            BGACADEMY: "BGACADEMY",
            COURSEMANAGER: "COURSEMANAGER",

            DEFAULT_ARGUMENT_ID: "LIBRARY_HOW_TO",

            ORDER: "ORDER",

            VOTE: "VOTE",

            PLAN_STATUS_TO_BE_APPROVED: "TO_BE_APPROVED",
            PLAN_STATUS_TO_VALIDATE: "TO_VALIDATE",

            PLAN_AREA_STATUS_SELECTED: "SELECTED",
            PLAN_AREA_STATUS_PROGRESS: "PROGRESS",
            PLAN_AREA_STATUS_WAITING_FEEDBACK: "WAITING_FEEDBACK",
            PLAN_AREA_STATUS_CLOSED: "CLOSED",

            NOT_PASSED: "NOT_PASSED",
            PASSED: "PASSED",

            TEXT_SURVEY_PASSED: "TEXT_SURVEY_PASSED",
            TEXT_SURVEY_NOT_PASSED: "TEXT_SURVEY_NOT_PASSED",   
            RESULT_TO_SHOW: "RESULT_TO_SHOW",
                RESULT_TO_SHOW_NONE: "NONE",
                RESULT_TO_SHOW_ALLANSWERS: "ALLANSWERS",
                RESULT_TO_SHOW_WRONGANSWERS: "WRONGANSWERS",
            
            MICROTACTIC_STATUS_PROGRESS: 'PROGRESS',
            MICROTACTIC_STATUS_SELECTED: 'SELECTED',
            MICROTACTIC_STATUS_CLOSED: 'CLOSED',

            EVENT_REQUEST_FEEDBACK: "EVENT_REQUEST_FEEDBACK",
            // Feedback 360 - Risposta feedback
            EVENT_RESPONSE_FEEDBACK: "EVENT_RESPONSE_FEEDBACK",
            // Nuovo invito al network
            EVENT_NEW_NETWORK_INVITATION: "EVENT_NEW_NETWORK_INVITATION",
            // Invito al network accettato
            EVENT_NETWORK_INVITATION_ACCEPTED: "EVENT_NETWORK_INVITATION_ACCEPTED",
            // Invito al network rifiutato
            EVENT_NETWORK_INVITATION_REFUSED: "EVENT_NETWORK_INVITATION_REFUSED",
            // Nuovo upload
            EVENT_NEW_UPLOAD: "EVENT_NEW_UPLOAD",
            // Chiusura Area
            EVENT_AREA_CLOSED: "EVENT_AREA_CLOSED",
            // Richiesta approvazione piabo
            EVENT_PLAN_REQUEST_APPROVAL: "EVENT_PLAN_REQUEST_APPROVAL",
            // Piano approvato
            EVENT_PLAN_APPROVED: "EVENT_PLAN_APPROVED",
            // Piano rifiutato Ara
            EVENT_PLAN_REFUSED: "EVENT_PLAN_REFUSED",
            // Richiesta validazione pinao
            EVENT_PLAN_REQUEST_VALIDATION: "EVENT_PLAN_REQUEST_VALIDATION",
            // Piano validato
            EVENT_PLAN_VALIDATION: "EVENT_PLAN_VALIDATION", 
            // Piano chiuso
            EVENT_PLAN_CLOSED: "EVENT_PLAN_CLOSED",
            // Microtattica completata
            EVENT_MICROTACTICS_COMPLETED: "EVENT_MICROTACTICS_COMPLETED",

            // Import status
            "COMPLETE": "COMPLETE",
            "ERROR": "ERROR",

            "DOCUMENT": "DOCUMENT",

            // Oggetto Atomico di base (solo in piattaforma senza dipendenze esterne)
            ITEM: "ITEM",
            // Oggetto contenitore di oggetti atomici (es: sezioni di progetti, slideshow, ecc)
            ITEMS_COLLECTION: "ITEMS_COLLECTION",
            // Oggetto contenitore collezioni (es: progetti)
            CONTAINER: "CONTAINER",
            // Oggetto Scorm/TinCan liberamente utilizzabile (crea una registration al primo accesso)
            SCORM_FREE: "SCORM_FREE",
            // Oggetto Scorm/TinCan utilizzabile solo su invito (va creata una registration prima manualmente)
            SCORM_INVITE: "SCORM_INVITE",
            // Oggetto sul DAM
            DAM_ITEM: "DAM_ITEM",

            "TAG": "TAG",
            "ATTACHMENT": "ATTACHMENT",
            "DAM_ATTACHMENT": "DAM_ATTACHMENT",

            // Tipo di fruizione
            "EXTERNAL_OBJECT_SINGLETON": "EXTERNAL_OBJECT_SINGLETON",
            // Tipo di apertura
            "EXTERNAL_OBJECT_OPEN_MODE": "EXTERNAL_OBJECT_OPEN_MODE",
            "NEW_WINDOW": "NEW_WINDOW",
            "FRAMESET": "FRAMESET",

            // Tipi di attributi dell'item
            // Link esterno
            LINK: "LINK",
            // Id dell'oggetto collegato (es: al DAM o al motore SCORM esterno)
            EXTERNAL_OBJECT_ID: "EXTERNAL_OBJECT_ID",
            // Valore dell'oggetto (es: ore formative)
            VALUE: "VALUE",
            // Autore del contenuto
            AUTHOR: "AUTHOR",
            // Editore del contenuto
            EDITOR: "EDITOR",
            // Anno di pubblicazione
            PUBLISH_YEAR: "PUBLISH_YEAR",
            // Luogo di pubblicazione
            PUBLISH_LOCATION: "PUBLISH_LOCATION",
            // Paese di pubblicazione
            PUBLISH_COUNTRY: "PUBLISH_COUNTRY",
            // ISBN del libro
            ISBN: "ISBN",
            // Anno di edizione
            EDITION_YEAR: "EDITION_YEAR",
            // Regia
            DIRECTOR: "DIRECTOR",
            // Scaffale
            SHELF: "SHELF",
            // Quantità
            QUANTITY: "QUANTITY",
            // COLLOCAZIONE
            COLLOCATION: "COLLOCATION",
            // Elenco di tags relativi a Conoscenze Tech
            TECH_COMPETENCES: "TECH_COMPETENCES",
            // Elenco di tags relativi a Conoscenze Soft
            SOFT_COMPETENCES: "SOFT_COMPETENCES",
            // Elenco di tags relativi a Clusters
            CLUSTERS: "CLUSTERS",
            // Elenco di tags relativi a Functional areas
            FUNCTIONAL_AREAS: "FUNCTIONAL_AREAS",
            // Elenco di tags relativi a Admin tags
            ADMIN_TAGS: "ADMIN_TAGS",
            // Elenco di tags relativi ai tag Stream
            STREAMS: "STREAMS",
            // Elenco di tags relativi al livello del contenuto
            LEVELS: "LEVELS",
            // Elenco di tags relativi a Argomento
            ARGUMENTS: "ARGUMENTS",
            // True se l'oggetto è di tipo tecnico
            TECHNICAL_SKILL: "TECHNICAL_SKILL",
            // True se l'oggetto è di tipo soft
            SOFT_SKILL: "SOFT_SKILL",
            // Tipologia di oggetto (video, audio, testo, ecc)
            OBJECT_TYPE: "OBJECT_TYPE",
            // Elenco di lingue in cui è disponibile il contenuto (es: video in inglese e in italiano, libro solo in inglese)
            LANG: "LANG",
            // Ratio del video collegato (e.g.: "16/9", "3/4")
            VIDEO_ASPECT_RATIO: "VIDEO_ASPECT_RATIO",

            SOFT_AND_TECH_SKILLS: "SOFT_AND_TECH_SKILLS",

            // Suggerimento di un contenuto
            SUGGESTION: "SUGGESTION",
            // Visibilità generica
            VISIBILITY: "VISIBILITY",

            // Object types
            MAGAZINES: "MAGAZINES",
            BOOKS: "BOOKS",
            LEARNING_PLAN: "LEARNING_PLAN",
            PROJECT: "PROJECT",
            SECTION: "SECTION",
            PODCAST: "PODCAST",
            GRAPHS: "GRAPHS",
            EBOOK: "EBOOK",
            ELEARNING: "ELEARNING",
            VIDEO: "VIDEO",

            SCORM: "SCORM",
            MULTIMEDIA: "MULTIMEDIA",
            RENTABLE: "RENTABLE",
            OTHER: "OTHER",
            SURVEY_ITEM: "SURVEY_ITEM",

            TYPE: "TYPE",
            STATUS: "STATUS",
            VIEWED: "VIEWED",
            NOT_VIEWED: "NOT_VIEWED",

            VALIDATED_SURVEY: "VALIDATED_SURVEY",

            ASKED: "ASKED",
            CONFIRMED: "CONFIRMED",
            ABORTED: "ABORTED",
            REFUSED: "REFUSED",
            RETURNED: "RETURNED",
            EXTENDED: "EXTENDED",
            LATE_RETURN: "LATE_RETURN",
            LATE_CONFIRM: "LATE_CONFIRM",
            QUEUED_ASK: "QUEUED_ASK",
            RETIRED: "RETIRED",

            "AVAIABLE": "AVAIABLE",
            "NOT_AVAIABLE": "NOT_AVAIABLE",

            "IMAGE": "IMAGE",
            "AUDIO": "AUDIO",

            "BOOK": "BOOK",
            "DVD": "DVD",
            "GRAPH": "GRAPH",
            "MAGAZINE": "MAGAZINE",

            SCORM_CONSUMED: "SCORM_CONSUMED",

            TECH_SKILL: "TECH_SKILL",

            CATEGORY: "CATEGORY",

            // Permette di gestire completamente i prestiti
            "LIBRARY_MANAGE_RENT" : "LIBRARY_MANAGE_RENT",
            // Permette di gestire i template
            LIBRARY_MANAGE_TEMPLATE: "LIBRARY_MANAGE_TEMPLATE",
            // Autorizzazione per chiedere un prestito un oggetto
            "LIBRARY_ASK_FOR_RENT": "LIBRARY_ASK_FOR_RENT",

            // Item eliminato
            EVENT_ITEM_DELETED: "EVENT_ITEM_DELETED",
            EVENT_ITEM_DELETED_TINCAN_VERB: "deleted",
            // Item modificato
            EVENT_ITEM_UPDATED: "EVENT_ITEM_UPDATED",
            EVENT_ITEM_UPDATED_TINCAN_VERB: "updated",
            // Tag eliminato
            EVENT_TAG_DELETED: "EVENT_TAG_DELETED",
            EVENT_TAG_DELETED_TINCAN_VERB: "deleted",
            // Tag modificato
            EVENT_TAG_UPDATED: "EVENT_TAG_UPDATED",
            EVENT_TAG_UPDATED_TINCAN_VERB: "updated",
            // L'utente ha fatto una ricerca
            EVENT_SEARCH: "EVENT_SEARCH",
            EVENT_SEARCH_TINCAN_VERB: "searched",
           // L'utente ha aperto l'oggetto
            EVENT_ITEM_OPEN: "EVENT_ITEM_OPEN",
            EVENT_ITEM_OPEN_TINCAN_VERB: "opened",
           // L'utente ha iniziato l'oggetto
            EVENT_ITEM_STARTED: "EVENT_ITEM_STARTED",
            EVENT_ITEM_STARTED_TINCAN_VERB: "started",
            // L'utente ha completato l'oggetto
            EVENT_ITEM_CONSUMED: "EVENT_ITEM_CONSUMED",
            EVENT_ITEM_CONSUMED_TINCAN_VERB: "consumed",
            // L'utente ha certificato l'oggetto
            EVENT_ITEM_CERTIFIED: "EVENT_ITEM_CERTIFIED",
            EVENT_ITEM_CERTIFIED_TINCAN_VERB: "qualified",
            // L'utente ha sugerito l'oggetto
            EVENT_ITEM_SUGGESTED: "EVENT_ITEM_SUGGESTED",
            EVENT_ITEM_SUGGESTED_TINCAN_VERB: "shared",

            "SURVEY": "SURVEY",

            // ChiaveSesso dell'utente loggato
            "MALE": "M",
            "FEMALE": "F",

            STRATEGIA_2021_ITEMID: "ce511886-ab17-4dfc-a6a8-081a69a1466a",

            // Card cover
            "CARD_COVER": {
                "ITEM_TYPE": "CARD_COVER",
                "MAX_HEIGHT": 98,
                "MAX_WIDTH": 280,
                "MAX_WEIGHT_MB": 0.2
            },

            PERCENTAGE: "PERCENTAGE",

            LETTERS_DIVISION: ["A, B, C, D", "E, F, G, H", "I, J, K, L", "M, N, O, P", "Q, R, S, T", "U, V, W, X", "Y, Z"],
            LETTERS_DIVISION_DISPLAYED: ["A B C D", "E F G H", "I J K L", "M N O P", "Q R S T", "U V W X", "Y Z"],
            LETTERS_DIVISION_COMPETENCES: ["A, B", "C, D", "E, F", "G, H", "I, J", "K, L", "M, N", "O, P", "Q, R", "S, T", "U, V", "W, X", "Y, Z"],
            LETTERS_DIVISION_COMPETENCES_DISPLAYED: ["A B", "C D", "E F", "G H", "I J", "K L", "M N", "O P", "Q R", "S T", "U V", "W X", "Y Z"],

            COMPETENCE_LIST: ["SOFT_COMPETENCES"],

            // Numero masso di MB degli upload
            UPLOAD_MAX_SIZE_MB: 2000,

            // Giorni passati massimi entro cui considerare un Item nuovo
            DAYS_TO_CONSIDER_NEW_ITEM: 14,

            // Sottotipo di un Item
            OBJECT_TYPE_SPECIALIZATION: "OBJECT_TYPE_SPECIALIZATION",

            COLOR: "COLOR",

            GENERIC_MAIL: "GENERIC_MAIL",
            GENERIC_NOTIFICATION: "GENERIC_NOTIFICATION",
            // Codice tipo evento per SAP
            SAP_CODICE_TIPO_EVENTO: "SAP_CODICE_TIPO_EVENTO",
            // Codice IT9054 per SAP
            SAP_MANDATORY_COURSE_CODE: "SAP_MANDATORY_COURSE_CODE",
        },

		/**
		 * Metodo che ritorna l'array con la lista di tipologie di oggetti
		*/
        getItemTypesList:() => {
            let l = [
                { code: LibraryApplicationData.constants.MAGAZINE, desc: <string>null},
                { code: LibraryApplicationData.constants.BOOK, desc: <string>null},
                { code: LibraryApplicationData.constants.LEARNING_PLAN, desc: <string>null},
                { code: LibraryApplicationData.constants.PROJECT, desc: <string>null},
                //{ code: LibraryApplicationData.constants.SECTION, desc: <string>null},
                { code: LibraryApplicationData.constants.PODCAST, desc: <string>null},
                { code: LibraryApplicationData.constants.GRAPH, desc: <string>null},
                { code: LibraryApplicationData.constants.DOCUMENT, desc: <string>null},
                { code: LibraryApplicationData.constants.EBOOK, desc: <string>null},
                { code: LibraryApplicationData.constants.ELEARNING, desc: <string>null},
                { code: LibraryApplicationData.constants.VIDEO, desc: <string>null},
                { code: LibraryApplicationData.constants.IMAGE, desc: <string>null},
                { code: LibraryApplicationData.constants.DVD, desc: <string>null}
            ];
            for (let i = 0; i < l.length; i++) {
                l[i].desc = $translate.instant("item.types." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con la lista dei sottotipi di oggetti
		*/
        getItemSubtypesList:() => {
            let l = [
                { code: LibraryApplicationData.constants.MAGAZINE, desc: <string>null},
                { code: LibraryApplicationData.constants.BOOK, desc: <string>null},
                { code: LibraryApplicationData.constants.PODCAST, desc: <string>null},
                { code: LibraryApplicationData.constants.GRAPH, desc: <string>null},
                { code: LibraryApplicationData.constants.DOCUMENT, desc: <string>null},
                { code: LibraryApplicationData.constants.EBOOK, desc: <string>null},
                { code: LibraryApplicationData.constants.ELEARNING, desc: <string>null},
                { code: LibraryApplicationData.constants.VIDEO, desc: <string>null},
                { code: LibraryApplicationData.constants.IMAGE, desc: <string>null},
                { code: LibraryApplicationData.constants.DVD, desc: <string>null}
            ];
            for (let i = 0; i < l.length; i++) {
                l[i].desc = $translate.instant("item.types." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con gli stati del piano e le relative descrizioni in lingua
		*/
        getMicroTacticsStatusList: ($translate: any) => {
            let l = [
                { code: LibraryApplicationData.constants.MICROTACTIC_STATUS_PROGRESS, desc: <string>null},
                { code: LibraryApplicationData.constants.MICROTACTIC_STATUS_SELECTED, desc: <string>null},
                { code: LibraryApplicationData.constants.MICROTACTIC_STATUS_CLOSED, desc: <string>null}
            ];
            for (let i = 0; i < l.length; i++) {
                l[i].desc = $translate.instant("microTactic.microTacticStatus." + l[i].code);
            }
			return l;
		}

		/**
		 * Metodo che ritorna l'array con i tipi documento e le relative descrizioni in lingua
		 /
		getDocumentUseTypesList: function($translate, _trainingId) {
            var l = [
                { code: this.constants.DOCUMENT_USE_TYPE_INVITE},
                { code: this.constants.DOCUMENT_USE_TYPE_PRE},
                { code: this.constants.DOCUMENT_USE_TYPE_POST},
                { code: this.constants.DOCUMENT_USE_TYPE_GENERIC}
            ];

						// Se trainingId è valorizzato aggiungo gli altri due tipi relativi
						if (_trainingId) {
							l.unshift(
								{ code: this.constants.DOCUMENT_USE_TYPE_START },
								{ code: this.constants.DOCUMENT_USE_TYPE_END }
							);
						}


            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("document.useTypes." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con gli stati del corso esistenti e le relative descrizioni in lingua
		 /
    getCourseStatusesList: function($translate) {
        var l = [
            { code: this.constants.COURSE_STATUS_DRAFT},
            { code: this.constants.COURSE_STATUS_CONFIRMED},
            { code: this.constants.COURSE_STATUS_CLOSED},
            { code: this.constants.COURSE_STATUS_PAUSED},
            { code: this.constants.COURSE_STATUS_CANCELLED}
        ];
        for (var i=0; i< l.length; i++) {
            l[i].desc = $translate.instant("course.statuses." + l[i].code);
        }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con gli stati del percorso esistenti e le relative descrizioni in lingua
		 /
    getTrainingStatusesList: function($translate) {
        var l = [
            { code: this.constants.TRAINING_STATUS_DRAFT},
            { code: this.constants.TRAINING_STATUS_CONFIRMED},
            { code: this.constants.TRAINING_STATUS_CLOSED},
            { code: this.constants.TRAINING_STATUS_PAUSED},
            { code: this.constants.TRAINING_STATUS_CANCELLED}
        ];
        for (var i=0; i< l.length; i++) {
            l[i].desc = $translate.instant("training.statuses." + l[i].code);
        }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con gli stati degli utenti del corso esistenti e le relative descrizioni in lingua
		 /
        getCourseUserStatusesList: function($translate) {
            var l = [
                { code: this.constants.COURSE_USER_STATUS_INSERTED},
                { code: this.constants.COURSE_USER_STATUS_INVITED},
                { code: this.constants.COURSE_USER_STATUS_CONFIRMED},
                { code: this.constants.COURSE_USER_STATUS_CANCELLED},
                { code: this.constants.COURSE_USER_STATUS_SUSPENDED},
                { code: this.constants.COURSE_USER_STATUS_SUSPENDED_OVERBOOKING},
                { code: this.constants.COURSE_USER_STATUS_CONFIRMED_OVERBOOKING},
                { code: this.constants.COURSE_USER_STATUS_PRESENT},
                { code: this.constants.COURSE_USER_STATUS_ABSENT}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("course.userStatus." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con i tipi di stile corso esistenti e le relative descrizioni in lingua
		 /
        getCourseStyleTypesList: function($translate) {
            var l = [
                { code: this.constants.COURSE_STYLE_TYPE_STANDARD},
                { code: this.constants.COURSE_STYLE_TYPE_CUSTOM},
                { code: this.constants.COURSE_STYLE_TYPE_EVENT}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("course.styles." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con i tipi di stile percorso esistenti e le relative descrizioni in lingua
		 /
        getTrainingStyleTypesList: function($translate) {
            var l = [
                { code: this.constants.TRAINING_STYLE_TYPE_STANDARD},
                { code: this.constants.TRAINING_STYLE_TYPE_CUSTOM}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("training.styles." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con i tipi di presenza alle date dei corsi e le relative descrizioni in lingua
		 /
        getCourseDateSeatTypesList: function($translate) {
            var l = [
                { code: this.constants.COURSE_DATE_SEAT_TYPE_PRESENCE},
                { code: this.constants.COURSE_DATE_SEAT_TYPE_WEBINAR}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("courseDate.seatType." + l[i].code);
            }
			return l;
		},

		/**
		 * Metodo che ritorna l'array con i tipi di lista delle date dei corsi e le relative descrizioni in lingua
		 /
        getCourseDateListTypesList: function($translate) {
            var l = [
                { code: this.constants.COURSE_DATE_LIST_TYPE_OPEN},
                { code: this.constants.COURSE_DATE_LIST_TYPE_CLOSED}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("courseDate.listType." + l[i].code);
            }
			return l;
		},

        /**
         * Metodo che ritorna l'array con i tipi di stato survey esistenti e le relative descrizioni in lingua
         /
        getSurveyStatusesList: function($translate) {
            var l = [
                { code: this.constants.SURVEY_STATUS_DRAFT},
                { code: this.constants.SURVEY_STATUS_CONFIRMED}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("survey.statuses." + l[i].code);
            }
            return l;
        },

        /**
         * Metodo che ritorna l'array con i tipi di stato template survey esistenti e le relative descrizioni in lingua
         /
        getSurveyTemplateStatusesList: function($translate) {
            var l = [
                { code: this.constants.SURVEY_TEMPLATE_STATUS_DRAFT},
                { code: this.constants.SURVEY_TEMPLATE_STATUS_CONFIRMED}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("template.statuses." + l[i].code);
            }
            return l;
        },

        /**
         * Metodo che ritorna l'array con i tipi corso
         /
        getCourseTypesList: function($translate) {
            var l = [
                { code: this.constants.COURSE_TYPE_COURSE},
                { code: this.constants.COURSE_TYPE_EVENT}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("course.types." + l[i].code);
            }
            return l;
        },

				/**
         * Metodo che ritorna l'array con i tipi percorso
         /
        getTrainingTypesList: function($translate) {
            var l = [
                { code: this.constants.TRAINING_TYPE_COURSE},
                { code: this.constants.TRAINING_TYPE_EVENT}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("training.types." + l[i].code);
            }
            return l;
        },

        /**
         * Metodo che ritorna l'array con i tipi corso
         /
        getFundingTypesList: function($translate) {
            var l = [
                { code: this.constants.FUNDING_TYPE_FBA},
                { code: this.constants.FUNDING_TYPE_FONDIR}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("fundingList.types." + l[i].code);
            }
            return l;
        },

        /**
         * Metodo che ritorna l'array con i tipi di stato delle rilevazioni esistenti e le relative descrizioni in lingua
         /
        getAssessmentStatusesList: function($translate) {
            var l = [
                { code: this.constants.ASSESSMENT_STATUS_DRAFT},
                { code: this.constants.ASSESSMENT_STATUS_CONFIRMED},
                { code: this.constants.ASSESSMENT_STATUS_CANCELLED}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("assessment.statuses." + l[i].code);
            }
            return l;
        },
        
        /**
         * Metodo che ritorna l'array che contiene l'indicazione tempo determinato (Si/No) e le relative descrizioni in lingua
         /
        getListTempoDeterminatoList: function($translate) {
            var l = [
                { 
                    code: this.constants.LIST_TEMPO_DETERMINATO_N,
                    desc: $translate.instant("generic.NO")
                },
                { 
                    code: this.constants.LIST_TEMPO_DETERMINATO_Y,
                    desc: $translate.instant("generic.YES")}
            ];
            return l;
        },

        /**
         * Metodo che ritorna l'array con i tipi di utilizzo nella data corso delle rilevazioni e le relative descrizioni in lingua
         /
        getAssessmentCourseDateTypesList: function($translate) {
            var l = [
                { code: this.constants.ASSESSMENT_COURSE_DATE_TYPE_FREE},
                { code: this.constants.ASSESSMENT_COURSE_DATE_TYPE_PRE},
                { code: this.constants.ASSESSMENT_COURSE_DATE_TYPE_POST}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("assessment.courseDateTypes." + l[i].code);
            }
            return l;
        },

        /**
         * Metodo che ritorna l'array con i tipi di stile dei questionari esistenti e le relative descrizioni in lingua
         /
        getSurveyStyleTypesList: function($translate) {
            var l = [
                { code: this.constants.SURVEY_STYLE_TYPE_STANDARD },
                { code: this.constants.SURVEY_STYLE_TYPE_STANDARD_NU }
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("survey.styleTypes." + l[i].code);
            }
            return l;
        },

        /**
         * Metodo che ritorna l'array con gli stati degli utenti del corso esistenti e le relative descrizioni in lingua
        /
        getAssessmentUserStatusesList: function($translate) {
            var l = [
                { code: this.constants.ASSESSMENT_USER_STATUS_INSERTED},
                { code: this.constants.ASSESSMENT_USER_STATUS_INVITED},
                { code: this.constants.ASSESSMENT_USER_STATUS_COMPLETED},
                { code: this.constants.ASSESSMENT_USER_STATUS_CANCELLED}
            ];
            for (var i=0; i< l.length; i++) {
                l[i].desc = $translate.instant("assessment.userStatus." + l[i].code);
            }
            return l;
        } */

    };
	return LibraryApplicationData;
    }])
};